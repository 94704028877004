import React from 'react'

const Button = ({text, type, id, classStyle, click}) => {
  return (
    <div>
        <button id={id} type={type} className={classStyle} onClick={click}>
            {text}
        </button>
    </div>
  )
}

export default Button
