export const options = [
  { value: "brigadavoluntaria", label: "Brigada Voluntaria" },
  { value: "direcaodefensiva", label: "Direção Defensiva" },
  { value: "ginasticalaboral", label: "Ginastica Laboral" },
  { value: "nr1", label: "NR-1" },
  { value: "nr5", label: "NR-5" },
  { value: "nr6", label: "NR-6" },
  { value: "nr10basico", label: "NR-10 Basico" },
  { value: "nr10sep", label: "NR-10 SEP" },
  { value: "nr11empilhadeira", label: "NR-11 Empilhadeira" },
  { value: "nr11guindauto", label: "NR-11 Guindauto" },
  { value: "nr11minicarregadeira", label: "NR-11 Minicarregadeira" },
  { value: "nr11plataforma", label: "NR-11 Plataforma" },
  { value: "nr11ponte", label: "NR-11 Ponte Rolante" },
  { value: "nr11retroescavadeira", label: "NR-11 Retroescavadeira" },
  { value: "nr11transpaleteira", label: "NR-11 Transpaleteira" },
  { value: "nr12", label: "NR-12" },
  { value: "nr13", label: "NR-13" },
  { value: "nr17checkout", label: "NR-17 Checkout" },
  { value: "nr17telemarketing", label: "NR-17 Telemarketing" },
  { value: "nr17transporte", label: "NR-17 Manual de peso" },
  { value: "nr17administrativas", label: "NR-17 Administrativa" },
  { value: "nr18", label: "NR-18" },
  { value: "nr20", label: "NR-20 Inflamaveis" },
  { value: "nr20basico", label: "NR-20 Basico" },
  { value: "nr20intermediario", label: "NR-20 Intermediario" },
  { value: "nr20avancadoi", label: "NR-20 Avançado 1" },
  { value: "nr20avancadoii", label: "NR-20 Avançado 2" },
  { value: "nr20especifico", label: "NR-20 Especifico" },
  { value: "nr22", label: "NR-22" },
  { value: "nr23", label: "NR-23" },
  { value: "nr26", label: "NR-26 Geral" },
  { value: "nr26laboratorio", label: "NR-26 Laboratorio" },
  { value: "nr29portuario", label: "NR-29 Portuário" },
  { value: "nr29sinaleiro", label: "NR-29 Sinaleiro" },
  { value: "nr29cpatp", label: "NR-29 CPATP" },
  { value: "nr31cipatr", label: "NR-31 CIPATR" },
  { value: "nr31agrotoxicos", label: "NR-31 Agrotóxicos" },
  { value: "nr31inicial", label: "NR-31 Inicial" },
  { value: "nr31periodico", label: "NR-31 Periódico" },
  { value: "nr32", label: "NR-32" },
  { value: "nr33supervisor", label: "NR-33 Supervisor" },
  { value: "nr33autorizado", label: "NR-33 Autorizado e Vigia" },
  { value: "nr34periodico", label: "NR-34 Periódico" },
  { value: "nr34admissional", label: "NR-34 Admissional" },
  { value: "nr35", label: "NR-35" },
  { value: "nr36", label: "NR-36" },
  { value: "pca", label: "PCA" },
  { value: "ppr", label: "PPR" },
  { value: "primeirossocorros", label: "Primeiros Socorros" },
];
