import React, {useState} from 'react';
import Button from './Button';
import { options } from '../constants';
import axios from 'axios';
import {toast} from 'react-toastify'
import Loading from './Loading';
import { MultiSelect } from "react-multi-select-component";
import apostilaIcon from '../assets/img/apostila_icon.png';
import {API_ROUTES} from '../utils/constants.js';

const CreateApostila = ({setFormulario}) => {

    const baseUrl = API_ROUTES.APOSTILA;
    const [apostila, setApostila] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [logo_width, setLogoWidth] = useState(250);
    const [logo_y, setLogoY] = useState(680);

    const handleChange = value => {
        setApostila(value);
    };

    const handleSubmit = event => {
      
      event.preventDefault();

    const criandoapostila = toast.info('Apostila sendo criada...', {
      theme: 'dark',
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      style: {
        top: '55px',
      },
      icon: ({theme, type}) => <img src={apostilaIcon} alt='apostila' />
    });
    

      const form = document.getElementById('form1');
      const logo = form.logo.files[0];
      const cor_primaria = form.cor_primaria.value;
      
      if (!apostila || apostila.length === 0) {
        alert('Por favor, selecione pelo menos uma apostila.');
        return;
      }
      
      setLoading(true);
      const apostilas = apostila.map(item => item.value);
      const data = new FormData();
      data.append('logo', logo);
      data.append('cor_primaria', cor_primaria);
      data.append('apostilas', apostilas);
      data.append('logo_width', logo_width); 
      data.append('logo_y', logo_y); 
      
      // header cors
      axios.post(baseUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
        onDownloadProgress: progressEvent => {

          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentage);
          if (percentage === 100) {
            setTimeout(() => {
              setLoading(false);
              toast.success('Apostila criada com sucesso!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                style: {
                  top: '55px',
                },
                theme: 'dark',
              });
            }, 400);
          }
        }

      }).then(response => {
        toast.dismiss(criandoapostila);
        setLoading(false);
        const blob = new Blob([response.data], { type: 'application/x-rar-compressed' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'apostilas.zip';
        link.click();
      }).catch(error => {
        toast.dismiss(criandoapostila);
        toast.error('Ocorreu um erro ao gerar a apostila.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
      )
    }
    
    // se loading for true, mostrar o loading e não o form
    if (loading === true) {
      return <Loading title="Criando sua apostila" text="Aguarde, sua apostila está sendo criada." setFormulario={setFormulario} percentage={progress} />
    }

    return (
      // Aumentar o tamanho
    <div className="w-full max-w-lg mx-auto bg-white p-4 rounded-md shadow-lg border border-gray-200">
      <form className="flex flex-wrap" id="form1" onSubmit={handleSubmit}>
          <h1 className='text-center w-full text-3xl pt-2 text-gray-900'>Gerador de Apostila</h1>
          <div className="w-full px-3 mb-6 md:mb-0 mt-4">
            <label
              className="block text-red-900 mb-2"
              htmlFor="grid-first-name"
            >
              Apostilas
            </label>
            <MultiSelect
              options={options}
              value={apostila}
              onChange={handleChange}
              labelledBy="Select"
              disableSearch={true}
              overrideStrings={{ selectSomeItems: "Selecione as apostilas", allItemsAreSelected: "Todas as apostilas selecionadas", selectAll: "Selecionar todas", search: "Pesquisar" }}
              className="block w-full p-2 border rounded-md text-gray-900"
            />
            <div className="mt-4">
              <label htmlFor="cor_primaria" className="block text-red-900 mb-2">
                Escolha uma cor
              </label>
              <input type="color" id="cor_primaria" name="cor_primaria" className="block w-full border rounded-md" />
            </div>
            <div className="mt-4">
              <label htmlFor="logo_width" className="block text-red-900 mb-2">
               Largura da logo e posição vertical (Padrão: 250px e 680px )
              </label>
              <input type="number" id="logo_width" name="logo_width" className="w-1/2 mr-4" value={logo_width} onChange={(e)=>(setLogoWidth(Number(e.target.value)))}/>
              <input type="number" id="logo_width" name="logo_y" className="w-2/5" value={logo_y} onChange={(e)=>(setLogoY(Number(e.target.value)))}/>
            </div>
            <div className="mt-4">
              <label htmlFor="logo" className="block text-red-900 mb-2">
                Carregue um arquivo
              </label>
              <input type="file" id="logo" name="logo" className="block w-full p-2 border rounded-md" required />
            </div>
            <div className="flex justify-between pt-4 pb-2">
              <Button text="Cancelar" type="button" classStyle="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded" click={() => setFormulario(null)} />
              <Button text="Gerar Apostila" onClick={() => setProgress(0)} type="submit" classStyle="bg-blue-600 hover:bg-green-700 text-white py-2 px-4 rounded" />
            </div>
          </div>
        </form>
        </div>
    )
};

export default  CreateApostila;