import { API_ROUTES } from "../utils/constants";
import axios from "axios";

export function storeTokenFromLocalStorage(token) {
    return localStorage.setItem('token', token)
}

export function getTokenFromLocalStorage() {
    return localStorage.getItem('token');
}

export function removeTokenFromLocalStorage() {
    localStorage.removeItem('token');
    window.location.href = '/';
}

export async function getAuthenticatedUser() {
    const defaultReturnObject = {authenticated: false, user: null};
    try {
        const token = getTokenFromLocalStorage();
        if(!token) return defaultReturnObject;
        const response = await axios({
            method: 'GET',
            url: API_ROUTES.GET_USER,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        const {authenticated = false} = response.data;
        return authenticated ? response.data : false;
    }catch(err) {
        console.log('getAuthenticatedUser, Something Went Wrong', err)
        return defaultReturnObject;
    }
}