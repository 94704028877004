import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify'
import Button from './Button';
import { options } from '../constants';
import { MultiSelect } from "react-multi-select-component";
import Loading from './Loading';
import certificateIcon from '../assets/img/certificate_icon.png';
import { API_ROUTES } from '../utils/constants.js';


const CreateCertificado = ({ setFormulario }) => {
  const baseUrl = API_ROUTES.CERTIFICATE;
  const [apostila, setApostila] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(300);

  const handleChange = value => {
    setApostila(value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    const criandocertificado = toast.info('Certificado sendo criado...', {
      theme: 'dark',
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      style: {
        top: '60px',
      },
      icon: ({ theme, type }) => <img src={certificateIcon} alt="certificado" />
    });

    const form = document.getElementById('formcertificate');

    const logocertificado = form.logocertificado.files[0];
    const corprimaria = form.corprimaria.value;

    if (!apostila || apostila.length === 0) {
      alert('Por favor, selecione pelo menos um certificado.');
      return;
    }

    setLoading(true);
    const apostilas = apostila.map(item => item.value);
    const data = new FormData();
    data.append('logocertificado', logocertificado);
    data.append('corprimaria', corprimaria);
    data.append('apostilas', apostilas);
    data.append('width', width);
    data.append('height', height);
    axios.post(baseUrl, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob',
      onDownloadProgress: progressEvent => {

        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => {
            setLoading(false);
            toast.success('Certificado criado com sucesso!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              style: {
                top: '60px',
              },
              theme: 'dark',
            });
          }, 400);
        }
      }

    }).then(response => {
      toast.dismiss(criandocertificado);
      const blob = new Blob([response.data], { type: 'application/x-rar-compressed' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'certificados.zip';
      link.click();
      setLoading(false);
    }).catch(error => {
      toast.dismiss(criandocertificado);
      setLoading(false);
    }
    )
  }

  // se loading for true, mostrar o loading e não o form
  if (loading === true) {
    return <Loading title="Criando certificado" text="Aguarde, seus certificados estão sendo criados." setFormulario={setFormulario} />
  }

  return (
    <div className="w-full max-w-lg mx-auto bg-white p-4 rounded-md shadow-lg border border-gray-200">
      <form className="flex flex-wrap" id="formcertificate" onSubmit={handleSubmit}>
        <h1 className='text-center w-full text-3xl pt-2 text-gray-900'>Gerador de Certificado</h1>
        <div className="w-full px-3 mb-6 md:mb-0 mt-4">
          <label
            className="block text-red-900  mb-2"
            htmlFor="grid-first-name"
          >
            Certificados
          </label>
          <MultiSelect
            options={options}
            value={apostila}
            onChange={handleChange}
            labelledBy="Select"
            disableSearch={true}
            overrideStrings={{ selectSomeItems: "Selecione os certificados", allItemsAreSelected: "Todas os certificados selecionados", selectAll: "Selecionar todas", search: "Pesquisar" }}
            className="block w-full p-2 border rounded-md text-gray-900"
          />
          <div className="mt-4">
            <label htmlFor="corprimaria" className="block text-red-900 mb-2">
              Escolha uma cor primaria
            </label>
            <input type="color" id="corprimaria" name="corprimaria" className="block w-full border rounded-md" />
          </div>
          <div className="mt-4">
            <label htmlFor="corprimaria" className="block text-red-900 mb-2">
              Escolha a Largura e Altura (Padrão: 300x300)
            </label>
            <div className="input-group flex">
              <input type="number" id="height" name="height" className="block border rounded-md mr-2" value={height} onChange={(e)=>(setHeight(e.target.value))}/>
              <span>x</span>
              <input type="number" id="width" name="width" className="block border rounded-md ml-2" value={width} onChange={(e)=>(setWidth(e.target.value))}/>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="logocertificado" className="block text-red-900 mb-2">
              Carregue um arquivo
            </label>
            <input type="file" id="logocertificado" name="logocertificado" className="block w-full p-2 border rounded-md" required />
          </div>
          <div className="flex justify-between pt-4 pb-2">
            <Button text="Cancelar" type="button" classStyle="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded" click={() => setFormulario(null)} />
            <Button text="Gerar Certificado" type="submit" classStyle="bg-blue-600 hover:bg-green-700 text-white py-2 px-4 rounded" />
          </div>
        </div>
      </form>
    </div>
  )
};

export default CreateCertificado;