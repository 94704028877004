const API_URL = "https://board-api.up.railway.app"; 
/* const API_URL = "http://localhost:3333";  */

export const API_ROUTES = {
  SIGN_IN: `${API_URL}/login`,
  GET_USER: `${API_URL}/auth/me`,
  LOGOUT: `${API_URL}/logout`,
  APOSTILA: `${API_URL}/pdf`,
  CERTIFICATE: `${API_URL}/certificate`,
  PLATFORM: `${API_URL}/platform`,
  UPDATE: `${API_URL}/updateenrolled`,
};

export const APP_ROUTES = {
  SIGN_IN: "/",
  HOME: "/board",
};
