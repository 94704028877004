import { useState } from 'react';
import axios from 'axios';
import { API_ROUTES, APP_ROUTES } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../lib/customHooks';
import { storeTokenFromLocalStorage } from '../lib/common';


const Login = () => {

  const navigate = useNavigate();
  const {user, authenticate} = useUser();
  if(user || authenticate) {
    navigate(APP_ROUTES.HOME);
  }

  const [errorMessage, setErrorMessage] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    axios.post(API_ROUTES.SIGN_IN, 
      {
        username: username,
        password: password
      })
      .then(response => {
        console.log(response);
        storeTokenFromLocalStorage(response.data.token);
        navigate(APP_ROUTES.HOME);
      })
      .catch(error => {
        console.log(error);
        setErrorMessage('Usuário ou senha inválidos');
      });
  }

  return (
    // Formulário de login
    <div className='flex flex-col items-center justify-center flex-1'>
      <div className='bg-white rounded-md p-8' style={{ backgroundColor: '#f5f5f5', maxWidth: '480px', width: '100%' }}>
        <h1 className='text-center text-3xl font-semibold text-gray-900 mb-3'>Login</h1>
        <form className='flex flex-col' id='loginform' onSubmit={handleSubmit}>
          <label htmlFor='username' className='mt-4 mb-2'>Usuário</label>
          <input type='text' id='username' name='username' className='p-4 border rounded-md mb-3' />
          <label htmlFor='password' className='mt-4 mb-2'>Senha</label>
          <input type='password' id='password' name='password' className='p-4 border rounded-md mb-6' />
          <button className='p-4 bg-red-900 text-white rounded-md'>Entrar</button>
        </form>
        {errorMessage && <p className='text-red-900 text-center mt-4'>{errorMessage}</p>}
      </div>
    </div>
  );
}

export default Login
