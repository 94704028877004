import axios from 'axios';
import { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Button from './Button';
import { BsFillEnvelopeAtFill, BsBrowserChrome, BsPencilFill, BsTrashFill } from "react-icons/bs";
import { FaClipboardList, FaFacebookSquare, FaBuilding, FaSearch } from "react-icons/fa";
import Modal from 'react-modal';
import { API_ROUTES } from '../utils/constants.js';
import Select from 'react-select';

const Control = ({ setFormulario }) => {

  const baseUrl = API_ROUTES.PLATFORM;
  const updatePlatform = API_ROUTES.UPDATE;
  const [idToDelete, setIdToDelete] = useState(null);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPlatformId, setSelectedPlatformId] = useState(null);
  const [selectColumns, setSelectColumns] = useState('enrolled');
  const [options, setOptions] = useState([
    { value: 'billingdate', label: 'Data' },
    { value: 'enrolled', label: 'Inscritos' },
    { value: 'enrollment_limit', label: 'Limite' },
  ]);

  const ModalOptions = [
    { value: 'Flex', label: <div className='flex items-center'><FaFacebookSquare className='m-1' />Flex</div> },
    { value: 'Empresa', label: <div className='flex items-center'><FaBuilding className='m-1' />Empresa</div> },
  ];

  const DefaultModalOption = { value: 'inscrever', label: <div className='flex items-center'><FaClipboardList className='m-1' />Inscrever</div>, isDisabled: true };

  const [data, setData] = useState([]);

  const [modalOption, setModalOption] = useState(DefaultModalOption);





  const customStyles = {
    rows: {
      style: {
        width: '60vw', // Substitua por sua altura desejada
        // Aqui você pode adicionar outros estilos como padding, font-size, etc.
      }
    },
    // Você pode adicionar mais estilos personalizados para outras partes do DataTable, se necessário
  };



  //  fazer um get na baseUrl e retornar o data
  useEffect(() => {
    axios.get(baseUrl)
      .then(response => {
        const data = response.data;
        data.map((item) => {
          console.log(item.inscription);
        })

        data.sort((a, b) => a[selectColumns] - b[selectColumns]);
        setData(data); //
      })
      .catch(error => {
        console.log(error);
      })




    axios.get(updatePlatform)
      .then(response => {
        console.log("atualizado");
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      })




  }, [])


  function openAddModal() {
    setAddModalIsOpen(true);
  };

  // No seu componente
  const [editFormData, setEditFormData] = useState({
    name: '',
    email: '',
    url: '',
    billingdate: '',
    enrollment_limit: '',
    inscription: '',
  });

  // Atualize a função openEditModal
  function openEditModal(id) {
    const platform = data.find((platform) => platform.id === id);
    setEditFormData({
      name: platform.name,
      email: platform.email,
      url: platform.url,
      billingdate: platform.billingdate,
      enrollment_limit: platform.enrollment_limit,
      inscription: platform.inscription,
    });
    setSelectedPlatformId(id);
    setEditModalIsOpen(true);
  }




  const confirmDelete = (id) => {
    setIdToDelete(id);
    setModalIsOpen(true);
  };

  const cancelDelete = () => {
    setIdToDelete(null);
    setModalIsOpen(false);
  };


  const handleAddPlatform = (event) => {
    event.preventDefault();


    // Captura os valores dos campos de entrada do formulário
    const name = event.target.name.value;
    const email = event.target.email.value;
    const url = event.target.url.value;
    const billingdate = parseInt(event.target.billingdate.value);
    const enrollment_limit = parseInt(event.target.enrollment_limit.value);
    const inscription = event.target.inscription.value;

    // Cria um objeto com os valores capturados do formulário
    const dataToAdd = {
      name,
      email,
      url,
      billingdate,
      enrollment_limit,
      inscription,
    };

    // Faz uma requisição POST para a API com os dados do formulário
    axios.post(baseUrl, dataToAdd)
      .then(response => {
        console.log(response.data);
        setAddModalIsOpen(false);

        const newPlatform = {
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          url: response.data.url,
          billingdate: response.data.billingdate,
          enrolled: response.data.enrolled,
          enrollment_limit: response.data.enrollment_limit,
          inscription: response.data.inscription,
        };

        setData(prevData => [...prevData, newPlatform]); // atualiza o estado dos dados da tabela

        toast.success('Plataforma cadastrada com sucesso!', {
          theme: 'dark',
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          style: {
            top: '55px',
          },
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleEditPlatform = (event) => {
    event.preventDefault();

    // Use os valores do editFormData
    const updatedFields = {
      name: editFormData.name,
      email: editFormData.email,
      url: editFormData.url,
      billingdate: parseInt(editFormData.billingdate),
      enrollment_limit: parseInt(editFormData.enrollment_limit),
      inscription: editFormData.inscription,
    };

    axios
      .patch(baseUrl + '/' + selectedPlatformId, updatedFields)
      .then((response) => {
        console.log(response.data);
        setEditModalIsOpen(false);

        const updatedPlatform = {
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          url: response.data.url,
          billingdate: response.data.billingdate,
          enrolled: response.data.enrolled,
          enrollment_limit: response.data.enrollment_limit,
          inscription: response.data.inscription,
        };

        const newData = data.map((item) => {
          if (item.id === selectedPlatformId) {
            return updatedPlatform;
          }
          return item;
        });

        setData(newData);

        toast.success('Plataforma atualizada com sucesso!', {
          theme: 'dark',
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          style: {
            top: '55px',
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deletePlatform = () => {
    axios.delete(baseUrl + "/" + idToDelete)
      .then(response => {
        console.log(response);
        toast.success('Plataforma excluída com sucesso!', {
          theme: 'dark',
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          style: {
            top: '55px',
          },
        });

        // Remover a linha da tabela com o id correspondente
        const newData = data.filter(item => item.id !== idToDelete);
        setData(newData);
      })
      .catch(error => {
        console.log(error);
      });

    setIdToDelete(null);
    setModalIsOpen(false);
  };



  const columns = [
    {
      name: 'Plataforma',
      selector: row => row.name,
    },
    {
      name: 'Data',
      selector: row => row.billingdate,
    },
    {
      name: 'Inscritos',
      selector: row => row.enrolled,
    },
    {
      name: 'Limite',
      selector: row => row.enrollment_limit,
    },
    {
      name: 'Status',
      //   se o status = Limite excedido -> red else -> green
      cell: row => (
        <div className="flex justify-center">
          <div className={row.enrolled > row.enrollment_limit ? 'bg-red-500 w-6 h-6 rounded-full' : 'bg-green-500 w-6 h-6 rounded-full'}></div>
        </div>
      ),

      type: 'element'
    },
    {
      name: 'Ações',
      cell: row => (
        <div className="flex justify-center">
          <div className="rounded-full p-1 bg-blue-500">
            <BsPencilFill
              onClick={() => openEditModal(row.id)}
              id={data.id}
              className="cursor-pointer text-white"
            />
          </div>
          <div className="rounded-full p-1 ml-2 bg-red-500">
            <BsTrashFill
              onClick={() => confirmDelete(row.id)}
              id={data.id}
              className="cursor-pointer text-white"
            />
          </div>
        </div>
      ),

      type: 'element'
    }, {
      name: 'Incrição',
      selector: row => (
        row.inscription === 'Flex' ? <div className='flex items-center'><FaFacebookSquare className='m-1' />Flex</div> : <div className='flex items-center'><FaBuilding className='m-1' />Empresa</div>
      ),
    }


  ];


  useMemo(() => {
    axios.get(baseUrl)
      .then(response => {
        const data = response.data;
        data.sort((a, b) => a[selectColumns] - b[selectColumns]);
        setData(data); //
      })
      .catch(error => {
        console.log(error);
      })
  }, [selectColumns]);



  function handleSelectedData(e) {
    setSelectColumns(e.target.value);
  }

  const actions = (
    <div className='flex'>
      <select value={selectColumns} onChange={handleSelectedData} className="mx-5 text-lg outline-none">
        {options.map((column, index) => (
          <option value={column.value} key={index}>
            {column.label}
          </option>
        ))}
      </select>
      <Button text="Cadastrar" type="submit" classStyle="bg-blue-600 hover:bg-green-700 text-sm text-white py-2 px-4 rounded" click={() => openAddModal()} />

    </div>
  )

  const handleModalOption = (e) => {
    //setModalOption;
    setEditFormData({ ...editFormData, inscription: e.value })
  };

  const ExpandedComponent = ({ data }) => (
    <>
      <div className='flex items-center pl-1'>
        <BsFillEnvelopeAtFill />
        <p className='ml-2 text-sm'>{data.email}</p>
      </div>
      <div className='flex items-center pl-1'>
        <BsBrowserChrome />
        <a href={data.url} rel="noreferrer" target="_blank" className='ml-2 text-sm'>{data.url}</a>
      </div>
    </>
  );

  return (
    // centralizar no meio da tela
    <div className="w-full max-w-6xl mx-auto px-6 sm:px-8 lg:px-10">
      <div className="bg-white p-10 rounded-lg shadow-xl border border-gray-300">
        {/* MODAL ADICIONAR PLATAFORMA */}
        <Modal
          isOpen={addModalIsOpen}
          onRequestClose={() => setAddModalIsOpen(false)}
          className="bg-white p-4 rounded-md shadow-lg border border-gray-200"
          overlayClassName="bg-gray-900 bg-opacity-50 fixed inset-0 z-50 flex items-center justify-center h-screen w-screen overflow-auto"
          appElement={document.getElementById('root')}
        >
          <div className="w-96 mx-auto">
            <div className="flex justify-center">
              <h1 className="text-2xl font-bold">Adicionar Plataforma</h1>
            </div>
            <form onSubmit={handleAddPlatform} className="py-2">
              <div className="mt-3">
                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Nome:</label>
                <input type="text" id="name" name="name" placeholder="flex ead" className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
              </div>
              <div className="mt-3">
                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email:</label>
                <input type="email" id="email" name="email" placeholder="flexocupacional@gmail.com" className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
              </div>
              <div className="mt-3">
                <label htmlFor="url" className="block text-gray-700 font-bold mb-2">Url:</label>
                <input type="text" id="url" name="url" placeholder="https://flexocupacional.com.br" className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
              </div>
              <div className="mt-3">
                <label htmlFor="data" className="block text-gray-700 font-bold mb-2">Dia pagamento:</label>
                <input type="number" id="data" name="billingdate" placeholder="10" className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
              </div>
              <div className="mt-3">
                <label htmlFor="limite" className="block text-gray-700 font-bold mb-2">Limite:</label>
                <input type="number" id="limite" name="enrollment_limit" placeholder="100" className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
              <div className="mt-3">
                <label htmlFor="inscricao" className="block text-gray-700 font-bold mb-2">
                  Inscrição:
                </label>
                <Select
                  defaultValue={DefaultModalOption}
                  options={[DefaultModalOption, ...ModalOptions]}
                  name="inscription"
                  onChange={(e) => { handleModalOption(e) }
                  } />
              </div>
              <div className="flex justify-center mt-3">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                  Adicionar
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* MODAL EDITAR PLATAFORMA */}
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={() => setEditModalIsOpen(false)}
          className="bg-white p-4 rounded-md shadow-lg border border-gray-200"
          overlayClassName="bg-gray-900 bg-opacity-50 fixed inset-0 z-50 flex items-center justify-center h-screen w-screen overflow-auto"
          appElement={document.getElementById('root')}
        >
          <div className="w-96 mx-auto">
            <div className="flex justify-center">
              <h1 className="text-2xl font-bold">Editar Plataforma</h1>
            </div>
            <form onSubmit={handleEditPlatform}>
              <div className="mt-3">
                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                  Nome:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={editFormData.name}
                  onChange={(event) =>
                    setEditFormData({ ...editFormData, name: event.target.value })
                  }
                />
              </div>
              <div className="mt-3">
                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={editFormData.email}
                  onChange={(event) =>
                    setEditFormData({ ...editFormData, email: event.target.value })
                  }
                />
              </div>
              <div className="mt-3">
                <label htmlFor="url" className="block text-gray-700 font-bold mb-2">
                  URL:
                </label>
                <input
                  type="text"
                  id="url"
                  name="url"
                  className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={editFormData.url}
                  onChange={(event) =>
                    setEditFormData({ ...editFormData, url: event.target.value })
                  }
                />
              </div>
              <div className="mt-3">
                <label htmlFor="data" className="block text-gray-700 font-bold mb-2">
                  Dia pagamento:
                </label>
                <input
                  type="number"
                  id="data"
                  name="billingdate"
                  className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={editFormData.billingdate}
                  onChange={(event) =>
                    setEditFormData({ ...editFormData, billingdate: event.target.value })
                  }
                />
              </div>
              <div className="mt-3">
                <label htmlFor="limite" className="block text-gray-700 font-bold mb-2">
                  Limite:
                </label>
                <input
                  type="number"
                  id="limite"
                  name="enrollment_limit"
                  className="border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={editFormData.enrollment_limit}
                  onChange={(event) =>
                    setEditFormData({ ...editFormData, enrollment_limit: event.target.value })
                  }
                />
              </div>
              <div className="mt-3">
                <label htmlFor="limite" className="block text-gray-700 font-bold mb-2">
                  Inscrição:
                </label>
                <Select
                  defaultValue={DefaultModalOption}
                  options={[DefaultModalOption, ...ModalOptions]}
                  onChange={(e) => { handleModalOption(e) }
                  } />
              </div>
              {/* Restante dos campos de input */}
              <div className="flex justify-center mt-3">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Editar
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cancelDelete}
          className="bg-white p-4 rounded-md shadow-lg border border-gray-200"
          overlayClassName="bg-gray-900 bg-opacity-50 fixed inset-0 z-50 flex items-center justify-center h-screen w-screen overflow-auto"
          appElement={document.getElementById('root')}
        >
          <div className="flex justify-center">
            <h1 className="text-2xl font-bold text-gray-800">
              Deseja realmente excluir esta plataforma?
            </h1>
          </div>
          <div className="flex justify-end mt-4">
            <button className="bg-gray-400 hover:bg-gray-500 text-sm text-gray-800 py-2 px-4 rounded-md mr-4" onClick={cancelDelete}>
              Cancelar
            </button>
            <button className="bg-red-600 hover:bg-red-700 text-sm text-white py-2 px-4 rounded-md" onClick={deletePlatform}>
              Excluir
            </button>
          </div>
        </Modal>

        <DataTable
          title="Controle de Plataformas"
          columns={columns}
          data={data}
          striped={true}
          dense={true}
          expandableRows={true}
          expandableRowsComponent={ExpandedComponent}
          actions={actions}
          pagination={true}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[5, 25, 50, 75, 100]}
          paginationComponentOptions={{
            rowsPerPageText: 'Linhas por página:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: false,
          }}
          defaultSortAsc={true}
          customStyles={customStyles}
        />
        <div className="flex justify-start">
          <Button text="Voltar" type="submit" classStyle="bg-red-600 hover:bg-red-700 text-sm text-white py-2 px-4 rounded" click={() => setFormulario(null)} />
        </div>
      </div>
    </div>
  );
};

export default Control