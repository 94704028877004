import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Login from "./components/Login";
import Home from "./components/Home";
import { APP_ROUTES } from "./utils/constants";

export default function App() {
  return (
    <div className="min-h-screen bg-gradient-to-l from-red-900 via-black to-gray-900 flex flex-col">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path={APP_ROUTES.HOME} element={<Home />} />
          <Route exact path="/" element={<Login />} />
        </Routes>
        <footer className="text-center py-4 text-gray-500 text-sm">
          Versão v1.0
        </footer>
      </BrowserRouter>
    </div>
  );
}
