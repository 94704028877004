import { flexocupacional } from '../assets';
import { removeTokenFromLocalStorage } from '../lib/common';
import Avatar from 'react-avatar';
import { Dropdown } from 'react-bootstrap';
import { useUser } from '../lib/customHooks';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {

  const {user, authenticated} = useUser();

  const handleLogout = () => {
    removeTokenFromLocalStorage();
  };

  if(!user || !authenticated) {
    return (
      <div className="flex justify-between items-center py-14">
        <img src={flexocupacional} className="w-60 mx-auto" alt="Flexocupacional" />
      </div>
    )
  }

  return (
    <section>
      <div className="flex justify-end items-center pr-12"> 
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            <Avatar id="avatarcomponent" round="8px" color='#00BFA6' name={`${user.username}`} size="35" textSizeRatio={2} className="mt-2" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="flex justify-between items-center">
        <img src={flexocupacional} className="w-60 mx-auto" alt="Flexocupacional" />
      </div>
      <ToastContainer position='top-center' autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </section>
  );    
}

export default Header;
