import React, { useState } from 'react';
import CreateApostila from './CreateApostila';
import CreateCertificado from './CreateCertificado';
import CreatePlatform from './CreatePlatform';
import Control from './Control';
import { useUser } from '../lib/customHooks';

const Home = () => {

  const {user, authenticated} = useUser();
  const [formulario, setFormulario] = useState(null);

  if(!user || !authenticated) {
    return <div className='text-white text-center'>Você não está logado</div>
  }

  return (
    // centralizar div no meio da tela
    <div className='flex flex-col items-center justify-center flex-1'>
      {formulario === null ? (
      <div className="">
        <div className='text-white text-center'>
        <h1 className='text-4xl font-bold mb-4'>{`Bem-vindo(a) ${user.username}!`}</h1>
        <p className='text-xl mb-8'>Escolha uma das opções abaixo:</p>
      </div>
      <div className='grid grid-cols-2 gap-4'>
        <button className='bg-green-900 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-600' onClick={() => setFormulario('Form1')}>Flex EAD Apostila</button>
        <button className='bg-blue-900 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600' onClick={() => setFormulario('Form2')}>Flex EAD Certificado</button>
        <button className="bg-purple-900 text-white font-bold py-2 px-4 rounded-lg hover:bg-purple-600" disabled>
          Flex EAD RPA<span className="ml-2">🛠️</span>
        </button>
        <button className='bg-red-900 text-white font-bold py-2 px-4 rounded-lg hover:bg-red-600' onClick={() => setFormulario('Form4')}>Flex EAD Control</button>
      </div>
      </div>
      ) : formulario === 'Form1' ? (
        <CreateApostila setFormulario={setFormulario} />
      ) : formulario === 'Form2' ? (
        <CreateCertificado setFormulario={setFormulario} />
      ) : formulario === 'Form3' ? (
        <CreatePlatform setFormulario={setFormulario} />
      ) : formulario === 'Form4' ? (
        <Control setFormulario={setFormulario} />
      ) : null}
    </div>
  );
  
};

export default Home;
