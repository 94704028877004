import React from 'react';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '../assets/groovyWalk.json';
import { BarLoader  } from 'react-spinners';

const Loading = ({title, text, setFormulario, percentage}) => {
  return (
    <div>
      <div className="w-full max-w-lg mx-auto bg-white p-4 rounded-md shadow-lg border border-gray-200">
        <h1 className="text-center w-full text-3xl p-1 text-gray-900">{title}</h1>
        <Lottie animationData={groovyWalkAnimation} className="w-80 mx-auto" />
        <BarLoader width={460} height={8} className='mt-3 rounded-full flex justify-center' color={'#82232a'} />
        <p className='mt-3'>{text}</p>
        <div className="flex justify-center mt-4">
            <button className="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded" onClick={() => setFormulario(null)}>
              Voltar
            </button>
        </div>
      </div>
    </div>
  )
}

export default Loading
