import { useState } from 'react';
import axios from 'axios';
import {toast} from 'react-toastify'
import Loading from './Loading';
import Button from './Button';
import platformIcon from '../assets/img/platform_icon.png';
import {API_ROUTES} from '../utils/constants.js';

export default function CreatePlatform({setFormulario}) {

    const baseUrl = `${API_ROUTES.PLATFORM}/generate`
    const [loading, setLoading] = useState(false);

    // Criar uma função pra submissão do form assim que o usuário clicar em confirmar
    function handleSubmit(e) {
        e.preventDefault();

        setLoading(true);

        const criandoapostila = toast.info('Plataforma sendo criada...', {
            theme: 'dark',
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            style: {
              top: '55px',
            },
            icon: ({theme, type}) => <img src={platformIcon} alt='apostila' />
          });

        const form = document.getElementById('cplatform');

        const formData = new FormData(form);

        const name = formData.get('name');
        const domain = formData.get('domain');
        const logo = formData.get('logo');
        const primary_color = formData.get('primary_color');

        const data = {
            name,
            domain,
            logo,
            primary_color
        }

        axios.post(baseUrl, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            console.log(response);
            setLoading(false);
            toast.dismiss(criandoapostila);
            toast.success('Plataforma criada com sucesso!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                style: {
                  top: '55px',
                },
                theme: 'dark',
              });
          }).catch(error => {
            console.log(error);
            setLoading(false);
            toast.dismiss(criandoapostila);
            toast.error('Ocorreu um erro ao gerar a plataforma.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
          })
    }

    if (loading === true) {
        return <Loading title="Criando sua plataforma" text="Aguarde, sua plataforma está sendo criada." setFormulario={setFormulario} />
    }

    return (
        <div className="w-full max-w-lg mx-auto bg-white p-6 rounded-md shadow-lg border border-gray-200">
    <form className="flex flex-wrap" onSubmit={handleSubmit} id="cplatform" method="POST">
        <h1 className='text-center w-full text-3xl pt-2 text-gray-900'>Gerador de Plataforma</h1>
        <div className="w-full px-2 mb-6 md:mb-0 mt-4">
            <div className="grid grid-cols-2 gap-4">
                <div className="mt-4">
                    <label htmlFor="name" className="block text-red-900 mb-2">Nome da Plataforma</label>
                    <input type="text" className="block w-full p-2 border rounded-md" name="name" id="name" placeholder="Flex EAD" />
                </div>

                <div className="mt-4">
                    <label htmlFor="domain" className="block text-red-900 mb-2">Dominio da Plataforma</label>
                    <input type="text" className="block w-full p-2 border rounded-md" name="domain" id="domain" placeholder="flexead.com.br" />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div className="mt-4">
                    <label htmlFor="username" className="block text-red-900 mb-2">Usuario banco de dados</label>
                    <input type="text" className="block w-full p-2 border rounded-md" name="username" id="username" placeholder="flexead" />
                </div>

                <div className="mt-4">
                    <label htmlFor="password" className="block text-red-900 mb-2">Senha banco de dados</label>
                    <input type="text" className="block w-full p-2 border rounded-md" name="password" id="password" placeholder="flexead_Ameise1" />
                </div>
            </div>

            <div className="mt-4">
                <label htmlFor="logo" className="block text-red-900 mb-2">Logo</label>
                <input type="file" className="block w-full p-2 border rounded-md" name="logo" id="logo" required />
            </div>

            <div className="mt-4">
                <label htmlFor="primary_color" className="block text-red-900 mb-2">
                    Escolha uma cor:
                </label>
                <input type="color" id="primary_color" name="primary_color" className="block w-full border rounded-md" />
            </div>

            <div className="flex justify-between pt-4 pb-2">
                <Button text="Cancelar" type="button" classStyle="bg-red-700 hover:bg-red-900 text-white py-2 px-4 rounded" click={() => setFormulario(null)} />
                <Button text="Gerar Plataforma" type="submit" classStyle="bg-blue-600 hover:bg-green-700 text-white py-2 px-4 rounded" />  
            </div>
        </div>
    </form>
</div>
  
    )
}

